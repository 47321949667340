import { currentUserStore } from "../store/current-user.store";

const modal = {
  dispatch,
};

/**
 * @param { JSX.Element } Component
 * @param { boolean } closable
 * @param { Record<string, any> } data
 */
function dispatch(componentName, data = {}, closable = true) {

  currentUserStore.emit("modal:show", {
    componentName,
    closable,
    data,
  });
}

export default modal;
