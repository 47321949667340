import { useState } from "react";
import { currentUserStore } from "../store/current-user.store";
import { Link } from "react-router-dom";
import userService from "../services/user.service";
import toast from "../utils/toast.utils";
import { useTranslate } from "../i18n/translation.i18n";

const HeaderComponent = () => {
  const { translate } = useTranslate();
  const staticLinks = [
    {
      className: "app-header-link",
      title: translate.components.header.title_1,
      link: "/#why-choose-us",
      key: "",
    },
    {
      className: "app-header-link",
      title: translate.components.header.title_2,
      link: "/#how-it-works",
      key: "",
    },
    {
      className: "app-header-link",
      title: translate.components.header.title_3,
      link:
        "mailto:hello@diasporemit.com?subject=" +
        translate.components.header.mailSubject +
        "&body=" +
        translate.components.header.mailBody_1 +
        "\n" +
        translate.components.header.mailBody_2,
      key: "",
    },
  ];

  const getNotAuthLinks = () => {
    return [
      ...staticLinks,
      {
        className: "app-header-link",
        title: translate.components.header.title_4,
        link: "/auth/sign-up",
        key: "",
      },
      {
        className: "app-header-link",
        title: translate.components.header.title_5,
        link: "/auth/login",
        key: "",
      },
    ];
  };

  const getAuthLinks = () => {
    return [
      ...staticLinks,
      {
        className: "app-header-link",
        title: translate.components.header.title_6,
        link: "/user/profile",
        key: "",
      },
      {
        className: "app-header-link",
        title: translate.components.header.title_7,
        link: "/",
        key: "logout",
      },
    ];
  };

  const [links, setLinks] = useState(
    !!currentUserStore.snapshot.userId ? getAuthLinks() : getNotAuthLinks()
  );

  currentUserStore.addActionListener("login", () => {
    setLinks(getAuthLinks());
  });
  currentUserStore.addActionListener("logout", () => {
    setLinks(getNotAuthLinks());
  });

  const toggleMenu = () => {
    document
      .querySelector("#app-header-links")
      .classList.toggle("list-disabled");
  };

  const handleClick = (value) => {
    toggleMenu();
    if (value === "logout") {
      userService.logout().then(() => {
        if (localStorage.currentUser) {
          localStorage.removeItem("currentUser");
          localStorage.removeItem("authToken");
        } else {
          sessionStorage.removeItem("currentUser");
          sessionStorage.removeItem("authToken");
        }
        toast.dispatch("success", "Success", "You're logged out.");
        currentUserStore.dispatch("logout").then(() => {
          window.location.reload();
        });
      });
    }
  };

  return (
    <>
      <header id="header-app">
        <Link to="/" id="app-title">
          <span>Diaspo Remit</span>
        </Link>
        <span id="app-header-links" className="list-disabled">
          {links.map((link, index) => (
            <Link
              key={index}
              to={link.link}
              onClick={() => handleClick(link.key.toLowerCase())}
            >
              <span className={link.className}>{link.title}</span>
            </Link>
          ))}
        </span>
        <span id="menu-toggle" onClick={toggleMenu}></span>
      </header>
    </>
  );
};

export default HeaderComponent;
