import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "../../utils/toast.utils";
import authService from "../../services/auth.service";
import { currentUserStore } from "../../store/current-user.store";
import { useTranslate } from "../../i18n/translation.i18n";

const ActivateAccountPage = () => {
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const [email, setEmail] = useState(localStorage.tempUserEmail || "");
  const [phoneNumber] = useState(localStorage.tempUserPhoneNumber || "");
  const [code, setActivationCode] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const resendCode = () => {
    authService
      .resendActivationCode({ typeCode: "email", email, phoneNumber })
      .then(() => {
        toast.dispatch("alert", "Alert", "New activation code sent.");
      });
  };

  const submitActivation = () => {
    if (email.length === 0 || code.length === 0) {
      return toast.dispatch("alert", "form info", "Field code is required.");
    }
    setDisabled(true);
    setIsPending(true);
    authService
      .activateAccount({ typeCode: "email", email, code, phoneNumber })
      .then(() => {
        authService
          .login({ email, password: localStorage.tempUserPassword })
          .then((currenUser) => {
            currentUserStore.dispatch("login", currenUser).then(() => {
              sessionStorage.setItem("currentUser", JSON.stringify(currenUser));
              sessionStorage.setItem("authToken", currenUser.token);
              setIsPending(false);

              toast.dispatch(
                "success",
                "Success",
                "Done! You are logged in successfully."
              );

              localStorage.removeItem("tempUserEmail");
              localStorage.removeItem("tempUserPassword");
              localStorage.removeItem("tempUserPhoneNumber");

              return navigate("/");
            });
          });
      })
      .catch((err) => {
        setIsPending(false);
        setDisabled(false);
        toast.dispatch("error", "Activation account error", err.message || err);
      });
  };

  return (
    <div id="account-activation-page" style={{ flexDirection: "column" }}>
      <h3>{translate.pages.activateAccount.h3_1}</h3>
      <div id="account-activation-form">
        <span className="input">
          <label htmlFor="email">{translate.pages.activateAccount.label_1}</label>
          <input
            className="input-text"
            type="text"
            id="email"
            value={email}
            disabled={!!localStorage.tempUserEmail}
            onChange={({ target }) => setEmail(target.value)}
          />
        </span>
        <span className="input">
          <label htmlFor="activation-code">{translate.pages.activateAccount.label_2}</label>
          <input
            className="input-text"
            type="text"
            id="activation-code"
            value={code}
            onChange={({ target }) => setActivationCode(target.value)}
          />
        </span>
        <button
          className="auth-button activation-button"
          onClick={submitActivation}
          disabled={disabled}
        >
          {translate.pages.activateAccount.button} {isPending && " ..."}
        </button>
        <span>
        {translate.pages.activateAccount.resendCodeText_1}{" "}
          <Link to="#" onClick={resendCode}>
          {translate.pages.activateAccount.resendCodeText_2}
          </Link>
          .
        </span>
      </div>
    </div>
  );
};

export default ActivateAccountPage;
