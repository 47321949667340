import { useState } from "react";
import authService from "../../services/auth.service";
import { currentUserStore } from "../../store/current-user.store";
import { Link, useNavigate } from "react-router-dom";
import toast from "../../utils/toast.utils";
import { useTranslate } from "../../i18n/translation.i18n";

const LoginPage = ({ routedata: routeData }) => {
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [disabled, setDisabled] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const submitLogin = () => {
    if (email.length === 0 || password.length === 0) {
      return toast.dispatch(
        "alert",
        "Form info",
        "Fields email and password are required."
      );
    }
    setDisabled(true);
    setIsPending(true);
    authService
      .login({ email, password })
      .then((currenUser) => {
        currentUserStore.dispatch("login", currenUser).then(() => {
          const storage = !rememberMe ? sessionStorage : localStorage;
          storage.setItem("currentUser", JSON.stringify(currenUser));
          storage.setItem("authToken", currenUser.token);
          setIsPending(false);

          toast.dispatch(
            "success",
            "Success",
            "Done! You are logged in successfully."
          );

          return navigate("/");
        });
      })
      .catch((err) => {
        setIsPending(false);
        setDisabled(false);
        toast.dispatch(
          "error",
          "Error",
          "Ooops! Something happened when logging in. Try again"
        );
      });
  };

  const togglePassworInputType = () => {
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  return (
    <>
      <div id="login-page">
        <div id="login-text">
          <h3>{translate.pages.login.h3_1}</h3>
          <p>{translate.pages.login.p_1}</p>
        </div>
        <div id="login-form">
          <span className="input">
            <label htmlFor="email">{translate.pages.login.label_1}</label>
            <input
              className="input-text"
              type="text"
              id="email"
              value={email}
              onChange={({ target }) => setEmail(target.value)}
            />
          </span>
          <span className="input">
            <label htmlFor="password">{translate.pages.login.label_2}</label>
            <input
              className="input-text password"
              type={passwordType}
              id="password"
              value={password}
              onChange={({ target }) => setPassword(target.value)}
            />
            <span onClick={togglePassworInputType}>
              {passwordType === "password" ? "SHOW" : "HIDE"}
            </span>
          </span>
          <span className="input input-checkbox" id="remember-me">
            <input
              id="remember-me-checkbox"
              type="checkbox"
              value={rememberMe}
              onChange={({ target }) => setRememberMe(target.value)}
            />
            <label htmlFor="remember-me-checkbox">
              {translate.pages.login.label_3}
            </label>
          </span>
          <button
            className="auth-button login-button"
            onClick={submitLogin}
            disabled={disabled || email.length === 0 || password.length === 0}
          >
            {translate.pages.login.button} {isPending && " ..."}
          </button>
          <span>
            <Link to="/auth/reset-password">
              {translate.pages.login.resetPasswordText}
            </Link>
            .
          </span>
          <span>
            <Link to="/auth/sign-up">
              {translate.pages.login.gotoSignUpText}
            </Link>
            .
          </span>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
