import { useState } from "react";
import authService from "../../services/auth.service";
import toast from "../../utils/toast.utils";
import { Link, useNavigate } from "react-router-dom";
import { useTranslate } from "../../i18n/translation.i18n";

const ResetPasswordPage = () => {
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [step, setStep] = useState("prepare");
  const [disabled, setDisabled] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const resendPasswordCode = () => {
    if (email.length === 0) {
      return;
    }
    authService
      .resendPasswordCode({ typeCode: "email", email })
      .then(() => {
        toast.dispatch("info", "Information", "Password code resent");
      })
      .catch((err) => {
        setIsPending(false);
        setDisabled(false);
        toast.dispatch("error", "Error", err.message || err);
      });
  };

  const sendPasswordCode = () => {
    if (email.length === 0) {
      toast.dispatch(
        "alert",
        "Form error",
        "Field email is required to reset password"
      );
      return;
    }
    setDisabled(true);
    setIsPending(true);
    authService
      .initResetPassword({ typeCode: "email", email })
      .then(() => {
        setDisabled(false);
        setIsPending(false);
        setStep("verify");
        toast.dispatch("info", "Information", "Reset password code sent.");
      })
      .catch((err) => {
        setIsPending(false);
        setDisabled(false);
        toast.dispatch("error", "Error", err.message || err);
      });
  };

  const verifyPasswordCode = () => {
    setDisabled(true);
    setIsPending(true);

    if (code.length === 0) {
      toast.dispatch(
        "alert",
        "Form error",
        "Field code is required to reset password"
      );
      return;
    }

    authService
      .verifyPasswordCode({ code, typeCode: "email", email })
      .then(() => {
        setDisabled(false);
        setIsPending(false);
        setStep("reset");
        toast.dispatch("info", "Information", "Reset password code verified.");
      })
      .catch((err) => {
        setIsPending(false);
        setDisabled(false);
        toast.dispatch("error", "Error", err.message || err);
      });
  };

  const resetPassword = () => {
    setDisabled(true);
    setIsPending(true);

    authService
      .resetPassword({ code, email, password, typeCode: "email" })
      .then(() => {
        navigate("/auth/login");
      })
      .catch((err) => {
        setIsPending(false);
        setDisabled(false);
        toast.dispatch("error", "Error", err.message || err);
      });
  };

  return (
    <div id="reset-password-page" style={{ flexDirection: "column" }}>
      <h3>{translate.pages.resetPassword.h3_1}</h3>
      <div id="reset-password-form">
        <span className="input">
          <label htmlFor="email">{translate.pages.resetPassword.label_1}</label>
          <input
            className="input-text"
            type="text"
            id="email"
            disabled={step !== "prepare"}
            value={email}
            onChange={({ target }) => setEmail(target.value)}
          />
        </span>
        {step === "prepare" && (
          <>
            <button
              className="auth-button send-password-button"
              onClick={sendPasswordCode}
              disabled={disabled || email.length === 0}
            >
              {translate.pages.resetPassword.button_1} {isPending && " ..."}
            </button>
          </>
        )}
        {step === "verify" && (
          <>
            <span className="input">
              <label htmlFor="code">{translate.pages.resetPassword.label_2}</label>
              <input
                className="input-text"
                type="text"
                id="code"
                value={code}
                onChange={({ target }) => setCode(target.value)}
              />
            </span>
            <button
              className="auth-button verify-code-button"
              onClick={verifyPasswordCode}
              disabled={disabled || code.length === 0}
            >
              {translate.pages.resetPassword.button_2} {isPending && " ..."}
            </button>
            <span>
              <Link to="#" onClick={resendPasswordCode}>
              {translate.pages.resetPassword.resendPasswordCodeText}
              </Link>
            </span>
          </>
        )}
        {step === "reset" && (
          <>
            <span className="input">
              <label htmlFor="password">{translate.pages.resetPassword.label_3}</label>
              <input
                className="input-text"
                type="text"
                id="password"
                value={password}
                onChange={({ target }) => setPassword(target.value)}
              />
            </span>
            <button
              className="auth-button reset-password-button"
              onClick={resetPassword}
              disabled={disabled || password.length === 0}
            >
              {translate.pages.resetPassword.button_3} {isPending && " ..."}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordPage;
