import { currentUserStore } from "../store/current-user.store";
import { api } from "../utils/api-config.utils";

const basePath = "/convertion";
const convertionService = {
  getFees,
  convertCurrency,
};

function getFees() {
  return api.getData(basePath + "/fees", null, {
    userId: currentUserStore.snapshot.userId,
  });
}

function convertCurrency(_from, _to = "XAF") {
  return api.getData(
    basePath + "/convert",
    { _from, _to },
    {
      userId: currentUserStore.snapshot.userId,
    }
  );
}

export default convertionService;
