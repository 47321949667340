const { Store } = require("@lakaflibrairies/state-reactive");

/**
 * @typedef {{
 *   userId: null | number;
 *   firstName: null | string;
 *   lastName: null | string;
 *   avatar: null | string;
 *   token: null | string;
 *   email: null | string;
 *   phoneNumber: null | string | number;
 *   birthday: string;
 *   city: string;
 *   country: string;
 *   fullAddress: string;
 *   gender: string;
 *   occupation: string;
 *   postalCode: number;
 *   province: string;
 *   roles: string[];
 *   token: string;
 *   whereToGoAfterLogin: string;
 * }} CurrentUser
 * @type { CurrentUser }
 */
const initialUser =
  !localStorage.currentUser && !sessionStorage.currentUser
    ? {
        userId: null,
        firstName: null,
        lastName: null,
        avatar: null,
        email: null,
        phoneNumber: null,
        birthday: null,
        city: null,
        country: null,
        fullAddress: null,
        gender: null,
        occupation: null,
        postalCode: null,
        province: null,
        roles: null,
        token: null,
        whereToGoAfterLogin: "/",
      }
    : JSON.parse(localStorage.currentUser || sessionStorage.currentUser);

export const currentUserStore = new Store({
  state: initialUser,
  mutations: {
    login(state, /** @type { CurrentUser } */ user) {
      return { ...state, ...user };
    },
    logout(state) {
      return { ...state, ...initialUser };
    },
    setWhereToGoAfterLogin(state, /** @type { string } */ value) {
      state.whereToGoAfterLogin = value;
      return state;
    },
    setEmail(state, /** @type { string } */ email) {
      state.email = email;
      return state;
    },
  },
  actions: {
    login({ commit }, /** @type { CurrentUser } */ user) {
      return new Promise((resolve, reject) => {
        commit("login", user);
        resolve();
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit("logout");
        resolve();
      });
    },
    setWhereToGoAfterLogin({ commit }, /** @type { string } */ value) {
      return new Promise((resolve, reject) => {
        commit("setWhereToGoAfterLogin", value);
        resolve();
      });
    },
    setEmail({ commit }, /** @type { string } */ email) {
      return new Promise((resolve, reject) => {
        commit("setEmail", email);
        resolve();
      });
    },
  }
});
