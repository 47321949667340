import { useState, useEffect } from "react";
import toast from "../../utils/toast.utils";
import transactionService from "../../services/transaction.service";
import modal from "../../utils/modal.utils";
import { useTranslate } from "../../i18n/translation.i18n";
import convertionService from "../../services/convertion.service";
import { useNavigate } from "react-router-dom";
import { cameroonianPhone } from "../../validators/phone/cameroon-phone.validator";

const supportedCurrencies = ["CAD", "USD", "EUR"];

const InitTransactionPage = () => {
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const [currency, setCurrency] = useState("EUR");
  const [coef, setCoef] = useState(1);
  const [quantity, setQuantity] = useState("");
  const [amount, setAmount] = useState(0);
  const [recipientFullName, setRecipientFullName] = useState("");
  const [recipientPhoneNumber, setRecipientPhoneNumber] = useState("");
  const [recipientEmail, setRecipientEmail] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [isPending, setIsPending] = useState(false);
  let timer = null;

  const reflectAmounts = (value) => {
    setQuantity(value);
    if (value !== "") {
      setAmount(+value * coef);
    }
  };

  const reflectCurrencyInAmount = async (value) => {
    setCurrency(value);

    await refreshCoef(value);
  };

  const refreshCoef = async (/** @type { number } */ curr = currency) => {
    /** @type { number } */
    try {
      const c = await convertionService.convertCurrency(curr);
      setAmount(+(+quantity * c).toFixed(3));
      setCoef(+c.toFixed(3));
    } catch (error) {
      toast.dispatch(
        "error",
        "Convertion error",
        "An error occurs during convertion."
      );
    }
  };

  const refreshCoefTimer = () => {
    !!timer && clearTimeout(timer);
    timer = setTimeout(async () => {
      await refreshCoef();
    }, 7000);
  };

  useEffect(() => {
    refreshCoef();
  }, [currency, quantity]);

  const verifyFields = () => {
    if (quantity <= 0) {
      toast.dispatch(
        "info",
        "Form error",
        "Field quantity must be a positive number"
      );
      return false;
    }

    if (!supportedCurrencies.includes(currency)) {
      toast.dispatch(
        "info",
        "Form error",
        "Field currency must be one in CAD, EUR and USD"
      );
      return false;
    }

    if (recipientFullName.trim().length === 0) {
      toast.dispatch(
        "info",
        "Form error",
        "Field recipient full name must be a valid name"
      );
      return false;
    }

    if (
      !cameroonianPhone.cameroonianPhoneNumberValidator(recipientPhoneNumber)
    ) {
      toast.dispatch(
        "info",
        "Form error",
        "Recipient phone number must be a valid cameroonian phone number"
      );
      return false;
    }
    return true;
  };

  const resetFields = () => {
    setQuantity(0);
    setAmount(0);
    setCurrency("");
    setRecipientFullName("");
    setRecipientPhoneNumber("0");
    setRecipientEmail("");
  };

  const submitTransaction = () => {
    if (!verifyFields()) {
      return;
    }

    const payload = {
      quantity: +quantity,
      coef,
      amount: +amount.toFixed(0),
      currency,
      recipientFullName,
    };

    if (recipientPhoneNumber.length !== 0 && recipientPhoneNumber !== "0") {
      payload.recipientPhoneNumber = recipientPhoneNumber;
    }
    if (recipientEmail.length !== 0) {
      payload.recipientEmail = recipientEmail;
    }

    transactionService
      .initTransaction(payload)
      .then(({ transactionCode, transactionId }) => {
        modal.dispatch(
          "CopyCodeComponent",
          { transactionCode, transactionId },
          true
        );
        toast.dispatch(
          "success",
          "Success",
          "transaction created successfully."
        );
        resetFields();
        navigate("/transactions/" + transactionId + "/details");
      })
      .catch((err) => {
        setIsPending(false);
        setDisabled(false);
        toast.dispatch("error", "Request error", err.message || err);
      });
  };

  return (
    <div id="init-transaction-page">
      <div id="init-transaction-text" style={{ paddingRight: "10px" }}>
        <h3>{translate.pages.initTransaction.h3_1}</h3>
        <p>{translate.pages.initTransaction.p_1}</p>
      </div>
      <div id="init-transaction-form">
        <span className="input">
          <label htmlFor="currency">
            {translate.pages.initTransaction.label_2} *
          </label>
          <select
            className="input-text"
            id="currency"
            value={currency}
            onChange={({ target }) => reflectCurrencyInAmount(target.value)}
          >
            <option value="CAD">CAD</option>
            <option value="USD">USD</option>
            <option value="EUR" defaultValue>
              EUR
            </option>
          </select>
        </span>
        <span className="input">
          <label htmlFor="quantity">
            {translate.pages.initTransaction.label_6} *
          </label>
          <input
            className="input-text"
            type="number"
            id="quantity"
            value={quantity}
            placeholder="Ex: 250"
            onChange={({ target }) => reflectAmounts(target.value)}
          />
        </span>
        <span className="input">
          <input
            style={{ marginTop: "18px" }}
            className="input-text"
            type="text"
            id="convertion"
            value={amount}
            disabled
          />
        </span>
        <span className="input">
          <label htmlFor="amount">
            {translate.pages.initTransaction.label_1} *
          </label>
          <input
            className="input-text"
            type="text"
            id="amount"
            value={amount.toFixed(0) + " XAF"}
            disabled
          />
        </span>
        <span className="input">
          <label htmlFor="recipientFullName">
            {translate.pages.initTransaction.label_3} *
          </label>
          <input
            className="input-text"
            type="text"
            id="recipientFullName"
            value={recipientFullName}
            onChange={({ target }) => setRecipientFullName(target.value)}
          />
        </span>
        <span className="input">
          <label htmlFor="recipientPhoneNumber">
            {translate.pages.initTransaction.label_4} *
          </label>
          <input
            className="input-text"
            type="number"
            id="recipientPhoneNumber"
            placeholder="Ex: 237690000000"
            value={recipientPhoneNumber}
            onChange={({ target }) => setRecipientPhoneNumber(target.value)}
          />
        </span>
        <span className="input">
          <label htmlFor="recipientEmail">
            {translate.pages.initTransaction.label_5}
          </label>
          <input
            className="input-text"
            type="email"
            id="recipientEmail"
            value={recipientEmail}
            onChange={({ target }) => setRecipientEmail(target.value)}
          />
        </span>
        <button
          className="auth-button activation-button"
          onClick={submitTransaction}
          disabled={disabled}
        >
          {translate.pages.initTransaction.button} {isPending && " ..."}
        </button>
      </div>
    </div>
  );
};

export default InitTransactionPage;
