import { useState } from "react";
import { useTranslate } from "../../i18n/translation.i18n";
import toast from "../../utils/toast.utils";
import transactionService from "../../services/transaction.service";
import { currentUserStore } from "../../store/current-user.store";
import { useNavigate } from "react-router-dom";

const SavePaymentCodeComponent = ({
  transactionCode,
  transactionId,
  closeModal,
}) => {
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const [code = "", setCode] = useState();
  const [disabled, setDisabled] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const { savePaymentCode } = transactionService.useTransaction(transactionId);

  const saveCode = (e) => {
    e.stopPropagation();
    if (code.trim().length === 0) {
      return toast.dispatch("error", "Form error", "Field code is required.");
    }

    savePaymentCode(code)
      .then(() => {
        toast.dispatch("success", "Success", "Transaction paid.");
        navigate("/user/transactions/")
        closeModal();
      })
      .catch((err) => {
        setIsPending(false);
        toast.dispatch("error", "Error", err.message || err);
      });
  };

  const render = () => {
    return (
      <div id="save-payment-code-component">
        <span className="text">
          <span className="input">
            <input
              className="input-text"
              type="text"
              id="code"
              value={code}
              onClick={(e) => e.stopPropagation()}
              placeholder={
                translate.components.savePaymentCodeComponent.placeholder
              }
              onChange={({ target }) => setCode(target.value)}
            />
          </span>
          <button className="button" disabled={disabled} onClick={saveCode}>
            {translate.components.savePaymentCodeComponent.button}{" "}
            {isPending && " ..."}
          </button>
        </span>
        <hr />
        <p>{translate.components.savePaymentCodeComponent.message}</p>
      </div>
    );
  };

  return render();
};

export default SavePaymentCodeComponent;
