/**
 * @typedef {{
 *   path: string;
 *   page: React.FunctionComponent | React.Component;
 *   children?: RouteList;
 *   data?: any;
 *   searchParams?: Record<string, string>;
 *   canActivate?: { () => boolean } | boolean;
 *   redirectOnFail?: string;
 *   redirectUrl?: string;
 *   name: string;
 * }} Route
 * @typedef { Array<Route> } RouteList
 */

import LoginPage from "../pages/auth/login.page";
import SignUpPage from "../pages/auth/sign-up.page";
import ActivateAccountPage from "../pages/auth/activate-account.page";
import ResetPasswordPage from "../pages/auth/reset-password.page.js";
import { currentUserStore } from "../store/current-user.store";

/** @type { RouteList } */
const authRoutes = [
  {
    path: "/auth/login",
    page: LoginPage,
    canActivate() {
      return !currentUserStore.snapshot.userId;
    },
    redirectOnFail: "/",
  },
  {
    path: "/auth/sign-up",
    page: SignUpPage,
    canActivate() {
      return !currentUserStore.snapshot.userId;
    },
    redirectOnFail: "/",
  },
  {
    path: "/auth/activate-account",
    page: ActivateAccountPage,
    canActivate() {
      return !currentUserStore.snapshot.userId && localStorage.tempUserEmail;
    },
    redirectOnFail: "/",
  },
  {
    path: "/auth/reset-password",
    page: ResetPasswordPage,
    canActivate() {
      return !currentUserStore.snapshot.userId;
    },
    redirectOnFail: "/",
  },
];

export default authRoutes;
