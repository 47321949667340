import { useEffect, useState } from "react";
import transactionService from "../../services/transaction.service";
import { Link, useNavigate } from "react-router-dom";
import { useTranslate } from "../../i18n/translation.i18n";

const UserTransactionsPage = () => {
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const loadTransactions = () => {
    setDisabled(true);
    setIsPending(true);
    transactionService
      .getTransactions({ page: pageIndex + 1 })
      .then(({ page, totalPages, data }) => {
        if (page <= totalPages) {
          setPageIndex(page);
        }
        setTotalPages(totalPages);
        if (!!data) {
          const t = [...transactions];
          t.push(...data);
          setTransactions(t);
        }
        setDisabled(false);
        setIsPending(false);
      });
  };

  const goToTransaction = (transactionId) => {
    return navigate("/transactions/" + transactionId + "/details");
  };

  useEffect(() => {
    loadTransactions();
  }, []);

  return (
    <div
      id="user-transactions-page"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "15px 10px",
      }}
    >
      <strong
        align="center"
        style={{
          display: "inline-block",
          width: "100%",
          margin: "10px 0 20px 0",
        }}
      >
        {translate.pages.userTransactions.strong}
      </strong>
      <ul
        style={{
          listStyle: "none",
          width: "100%",
          margin: 0,
          padding: 0,
        }}
      >
        {isPending && (
          <li align="center">{translate.pages.userTransactions.loading}</li>
        )}
        {!isPending && transactions.length === 0 && (
          <li align="center">
            {translate.pages.userTransactions.noTransaction}
          </li>
        )}
        {!isPending && transactions.map((transaction, key) => (
          <li
            key={key}
            align="center"
            className="transaction-item"
            onClick={() => goToTransaction(transaction.id)}
          >
            <span className="info">
              <label>
                <b>{translate.pages.userTransactions.label_1}</b>
              </label>
              <span>{transaction.transactionCode}</span>
            </span>
            <span className="info">
              <label>
                <b>{translate.pages.userTransactions.label_2}</b>
              </label>
              <span>{transaction.quantity}</span>
            </span>
            <span className="info">
              <label>
                <b>{translate.pages.userTransactions.label_3}</b>
              </label>
              <span>{transaction.currency}</span>
            </span>
          </li>
        ))}
        {totalPages !== pageIndex && (
          <li
            align="center"
            disabled={disabled}
            style={{
              cursor: "pointer",
              borderRadius: "5px",
              backgroundColor: "#777",
              color: "white",
              padding: "7px 0",
              marginTop: "10px",
            }}
            onClick={() => loadTransactions()}
          >
            {translate.pages.userTransactions.moreTransactions}{" "}
            {isPending && "..."}
          </li>
        )}
      </ul>
      <div id="more-options" style={{ width: "100%", marginTop: "15px" }}>
        <h4 style={{ margin: 0, padding: 0 }}>
          {translate.pages.userTransactions.h4_1}
        </h4>
        <hr style={{ margin: "5px 0 10px 0" }} />
        <Link to="/transactions/init">
          {translate.pages.userTransactions.link_1}
        </Link>
        <br />
        <Link to="/transactions/track">
          {translate.pages.userTransactions.link_2}
        </Link>
      </div>
    </div>
  );
};

export default UserTransactionsPage;
