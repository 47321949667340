import { useState } from "react";
import { currentUserStore } from "../store/current-user.store";
import CopyCodeComponent from "./modals/copy-code.component";
import SavePaymentCodeComponent from "./modals/save-payment-code.component"

const modalComponents = {
  CopyCodeComponent,
  SavePaymentCodeComponent
};

const ModalComponent = () => {
  const [modalStatus, setModelStatus] = useState(false);
  let [element, setElement] = useState(<></>);
  const [isClosable, setIsClosable] = useState(false);

  currentUserStore.listenAction("modal:show", ({ data }) => {
    const { componentName, closable, data: modalData } = data;

    const Component = modalComponents[componentName];
    setElement(
      <Component
        closeModal={() => {
          setModelStatus(false);
          setElement(null);
        }}
        {...modalData}
      />
    );
    setIsClosable(closable);
    setModelStatus(!modalStatus);
  });

  window.document.addEventListener("keyup", (e) => {
    const { key } = e;
    if (["Escape", "Esc"].includes(key)) {
      if (modalStatus === true) {
        setModelStatus(false);
        setElement(null);
      }
    }
  });

  return (
    <>
      {modalStatus && (
        <div id="modal-component" onClick={() => setModelStatus(!modalStatus)}>
          {isClosable && (
            <span
              className="close-modal"
              onClick={() => setModelStatus(!modalStatus)}
            ></span>
          )}
          <div className="modal-container">{element}</div>
        </div>
      )}
    </>
  );
};

export default ModalComponent;
