import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "../../utils/toast.utils";
import authService from "../../services/auth.service";
import { computeLang, useTranslate } from "../../i18n/translation.i18n";
import countriesList from "../../utils/constants/conutries.json";

const countries = Object.keys(countriesList).map((code) => ({
  code,
  name:
    countriesList[code].translations[computeLang()] ||
    countriesList[code].countryEnglishName,
}));

const formatCurrentDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  }`;
};

const SignUpPage = () => {
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fullAddress, setFullAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [country, setCountry] = useState("");
  const [occupation, setOccupation] = useState("");
  const [birthday, setBirthday] = useState(formatCurrentDate());
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [aggree, setAggree] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [disabled, setDisabled] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});

  const validateFields = () => {
    setDisabled(true);
    setIsPending(true);

    const flags = [];

    if (!aggree) {
      setErrorMessages((prev) => ({ ...prev, aggree: true }));
      flags.push(true);
    } else {
      setErrorMessages((prev) => ({ ...prev, aggree: false }));
    }

    if (!/^[1-9]\d{10,}$/.test(phoneNumber)) {
      setErrorMessages((prev) => ({ ...prev, phoneNumber: true }));
      flags.push(true);
    } else {
      setErrorMessages((prev) => ({ ...prev, phoneNumber: false }));
    }

    if (firstName.trim().length === 0) {
      setErrorMessages((prev) => ({ ...prev, firstName: true }));
      flags.push(true);
    } else {
      setErrorMessages((prev) => ({ ...prev, firstName: false }));
    }

    if (lastName.trim().length === 0) {
      setErrorMessages((prev) => ({ ...prev, lastName: true }));
      flags.push(true);
    } else {
      setErrorMessages((prev) => ({ ...prev, lastName: false }));
    }

    if (fullAddress.trim().length === 0) {
      setErrorMessages((prev) => ({ ...prev, fullAddress: true }));
      flags.push(true);
    } else {
      setErrorMessages((prev) => ({ ...prev, fullAddress: false }));
    }

    if (occupation.trim().length === 0) {
      setErrorMessages((prev) => ({ ...prev, occupation: true }));
      flags.push(true);
    } else {
      setErrorMessages((prev) => ({ ...prev, occupation: false }));
    }

    if (!Object.keys(countriesList).includes(country)) {
      setErrorMessages((prev) => ({ ...prev, country: true }));
      flags.push(true);
    } else {
      setErrorMessages((prev) => ({ ...prev, country: false }));
    }

    if (!/^[A-Z0-9]{4,}$/.test(postalCode)) {
      setErrorMessages((prev) => ({ ...prev, postalCode: true }));
    } else {
      setErrorMessages((prev) => ({ ...prev, postalCode: false }));
    }

    if (province.trim().length === 0) {
      setErrorMessages((prev) => ({ ...prev, province: true }));
      flags.push(true);
    } else {
      setErrorMessages((prev) => ({ ...prev, province: false }));
    }

    if (city.trim().length === 0) {
      setErrorMessages((prev) => ({ ...prev, city: true }));
      flags.push(true);
    } else {
      setErrorMessages((prev) => ({ ...prev, city: false }));
    }

    if (password.trim().length < 6) {
      setErrorMessages((prev) => ({ ...prev, password: true }));
      flags.push(true);
    } else {
      setErrorMessages((prev) => ({ ...prev, password: false }));
    }

    if (!["man", "woman"].includes(gender)) {
      setErrorMessages((prev) => ({ ...prev, gender: true }));
      flags.push(true);
    } else {
      setErrorMessages((prev) => ({ ...prev, gender: false }));
    }

    if (email.trim().length === 0) {
      setErrorMessages((prev) => ({ ...prev, email: true }));
      flags.push(true);
    } else {
      setErrorMessages((prev) => ({ ...prev, email: false }));
    }

    const age = new Date().getFullYear() - new Date(birthday).getFullYear();

    if (age <= 17 || age > 100) {
      setErrorMessages((prev) => ({ ...prev, birthday: true }));
      flags.push(true);
    } else {
      setErrorMessages((prev) => ({ ...prev, birthday: false }));
    }

    return flags.length === 0;
  };

  const submitSignUp = () => {
    const isValid = validateFields();
    if (!isValid) {
      setIsPending(false);
      setDisabled(false);
      return;
    }

    authService
      .signUp({
        birthday,
        city,
        country,
        email,
        firstName,
        fullAddress,
        gender,
        lastName,
        occupation,
        password,
        phoneNumber,
        postalCode,
        province,
      })
      .then((result) => {
        localStorage.setItem("tempUserEmail", email);
        localStorage.setItem("tempUserPassword", password);
        localStorage.setItem("tempUserPhoneNumber", phoneNumber);
        setIsPending(false);

        toast.dispatch(
          "success",
          "Success",
          "Your account has been created successfully."
        );

        return navigate("/auth/activate-account");
      })
      .catch((err) => {
        setIsPending(false);
        setDisabled(false);
        toast.dispatch("error", "Sign up error", err.message || err);
      });
  };

  const togglePassworInputType = () => {
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  return (
    <>
      <div id="sign-up-page">
        <div id="sign-up-text">
          <h3>{translate.pages.signUp.h3_1}</h3>
          <p>{translate.pages.signUp.p_1}</p>
          <p>{translate.pages.signUp.p_2}</p>
        </div>
        <div id="sign-up-form">
          <span className="input">
            <label htmlFor="firstName">{translate.pages.signUp.label_1}</label>
            <input
              className="input-text"
              type="text"
              id="firstName"
              value={firstName}
              onChange={({ target }) => setFirstName(target.value)}
            />
          </span>
          {errorMessages.firstName && (
            <span style={{ color: "#c01708" }}>
              {translate.errorMessages.signUp.fields.firstName}
            </span>
          )}
          <span className="input">
            <label htmlFor="lastName">{translate.pages.signUp.label_2}</label>
            <input
              className="input-text"
              type="text"
              id="lastName"
              value={lastName}
              onChange={({ target }) => setLastName(target.value)}
            />
          </span>
          {errorMessages.lastName && (
            <span style={{ color: "#c01708" }}>
              {translate.errorMessages.signUp.fields.lastName}
            </span>
          )}
          <span className="input">
            <label htmlFor="gender">{translate.pages.signUp.label_3}</label>
            <select
              className="input-text"
              type="text"
              id="gender"
              value={gender}
              onChange={({ target }) => setGender(target.value)}
            >
              <option value="" disabled checked="checked">
                {translate.pages.signUp.option_1}
              </option>
              <option value="man">{translate.pages.signUp.option_2}</option>
              <option value="woman">{translate.pages.signUp.option_3}</option>
            </select>
          </span>
          {errorMessages.gender && (
            <span style={{ color: "#c01708" }}>
              {translate.errorMessages.signUp.fields.gender}
            </span>
          )}
          <span className="input">
            <label htmlFor="fullAddress">
              {translate.pages.signUp.label_4}
            </label>
            <input
              className="input-text"
              type="text"
              id="fullAddress"
              value={fullAddress}
              onChange={({ target }) => setFullAddress(target.value)}
            />
          </span>
          {errorMessages.fullAddress && (
            <span style={{ color: "#c01708" }}>
              {translate.errorMessages.signUp.fields.fullAddress}
            </span>
          )}
          <span className="input">
            <label htmlFor="postalCode">{translate.pages.signUp.label_5}</label>
            <input
              className="input-text"
              type="text"
              id="postalCode"
              value={postalCode}
              onChange={({ target }) =>
                setPostalCode(target.value.toUpperCase())
              }
            />
          </span>
          {errorMessages.postalCode && (
            <span style={{ color: "#c01708" }}>
              {translate.errorMessages.signUp.fields.postalCode}
            </span>
          )}
          <span className="input">
            <label htmlFor="city">{translate.pages.signUp.label_6}</label>
            <input
              className="input-text"
              type="text"
              id="city"
              value={city}
              onChange={({ target }) => setCity(target.value)}
            />
          </span>
          {errorMessages.city && (
            <span style={{ color: "#c01708" }}>
              {translate.errorMessages.signUp.fields.city}
            </span>
          )}
          <span className="input">
            <label htmlFor="province">{translate.pages.signUp.label_7}</label>
            <input
              className="input-text"
              type="text"
              id="province"
              value={province}
              onChange={({ target }) => setProvince(target.value)}
            />
          </span>
          {errorMessages.province && (
            <span style={{ color: "#c01708" }}>
              {translate.errorMessages.signUp.fields.province}
            </span>
          )}
          <span className="input">
            <label htmlFor="country">{translate.pages.signUp.label_8}</label>
            <select
              className="input-text"
              type="text"
              id="country"
              value={country}
              onChange={({ target }) => setCountry(target.value)}
            >
              {countries.map((c, index) => (
                <option value={c.code} key={index}>
                  {c.name}
                </option>
              ))}
            </select>
          </span>
          {errorMessages.country && (
            <span style={{ color: "#c01708" }}>
              {translate.errorMessages.signUp.fields.country}
            </span>
          )}
          <span className="input">
            <label htmlFor="occupation">{translate.pages.signUp.label_9}</label>
            <input
              className="input-text"
              type="text"
              id="occupation"
              value={occupation}
              onChange={({ target }) => setOccupation(target.value)}
            />
          </span>
          {errorMessages.occupation && (
            <span style={{ color: "#c01708" }}>
              {translate.errorMessages.signUp.fields.occupation}
            </span>
          )}
          <span className="input">
            <label htmlFor="birthday">{translate.pages.signUp.label_10}</label>
            <input
              className="input-text"
              type="date"
              id="birthday"
              value={birthday}
              onChange={({ target }) => setBirthday(target.value)}
            />
          </span>
          {errorMessages.birthday && (
            <span style={{ color: "#c01708" }}>
              {translate.errorMessages.signUp.fields.birthday}
            </span>
          )}
          <span className="input">
            <label htmlFor="email">{translate.pages.signUp.label_11}</label>
            <input
              className="input-text"
              type="text"
              id="email"
              value={email}
              onChange={({ target }) => setEmail(target.value)}
            />
          </span>
          {errorMessages.email && (
            <span style={{ color: "#c01708" }}>
              {translate.errorMessages.signUp.fields.email}
            </span>
          )}
          <span className="input">
            <label htmlFor="phone-number">
              {translate.pages.signUp.label_12}
            </label>
            <input
              className="input-text"
              type="number"
              id="phone-number"
              value={phoneNumber}
              onChange={({ target }) => setPhoneNumber(target.value)}
            />
          </span>
          {errorMessages.phoneNumber && (
            <span style={{ color: "#c01708" }}>
              {translate.errorMessages.signUp.fields.phoneNumber}
            </span>
          )}
          <span className="input">
            <label htmlFor="password">{translate.pages.signUp.label_13}</label>
            <input
              className="input-text password"
              type={passwordType}
              id="password"
              value={password}
              onChange={({ target }) => setPassword(target.value)}
            />
            <span onClick={togglePassworInputType}>
              {passwordType === "password"
                ? translate.pages.signUp.show
                : translate.pages.signUp.hide}
            </span>
          </span>
          {errorMessages.password && (
            <span style={{ color: "#c01708" }}>
              {translate.errorMessages.signUp.fields.password}
            </span>
          )}
          <span className="input input-checkbox" id="remember-me">
            <input
              id="aggree"
              type="checkbox"
              value={aggree}
              onChange={({ target }) => setAggree(target.value)}
            />
            <label htmlFor="aggree">
              {translate.pages.signUp.label_14_1}{" "}
              <Link to="#">{translate.pages.signUp.label_14_2}.</Link>
            </label>
          </span>
          {errorMessages.aggree && (
            <span style={{ color: "#c01708" }}>
              {translate.errorMessages.signUp.fields.aggree}
            </span>
          )}
          <button
            className="auth-button sign-up-button"
            onClick={submitSignUp}
            disabled={disabled}
          >
            {translate.pages.signUp.button} {isPending && " ..."}
          </button>
          <span>
            <Link to="/auth/login">{translate.pages.signUp.gotoLogin}</Link>.
          </span>
        </div>
      </div>
    </>
  );
};

export default SignUpPage;
