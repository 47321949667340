import { Link } from "react-router-dom";
import { currentUserStore } from "../../store/current-user.store";
import { computeLang, useTranslate } from "../../i18n/translation.i18n";
import countriesList from "../../utils/constants/conutries.json";

const capitalize = (/** @type { string } */ value) => {
  const letters = value.split("");
  letters[0] = letters[0].toUpperCase();
  return letters.join("");
};

const UserProfilePage = () => {
  const { translate } = useTranslate();
  const snapshot = currentUserStore.snapshot;
  const { whereToGoAfterLogin, userId, token, ...user } = snapshot;

  const formatDate = (value) => {
    const date = new Date(value);
    return `${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()} - ${
      date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)
    } - ${date.getFullYear()}`;
  };
  return (
    <div id="pofile-page" style={{ flexDirection: "column" }}>
      <div id="profile-banner" style={{ width: "100%" }}>
        <div id="cover">
          <img src="/imgs/default-cover.png" alt="default-cover.png" />
        </div>
        <img
          id="avatar"
          src="/imgs/default-avatar.png"
          alt="default-avatar.png"
        />
        <label id="user-full-name">
          {user.firstName} {user.lastName}
        </label>
      </div>
      <div id="profile-info" style={{ width: "100%" }}>
        <span className="user-info-item">
          <label className="info-property">
            {translate.pages.userProfile.label_1}
          </label>
          <label className="info-value">{user.email}</label>
        </span>
        <span className="user-info-item">
          <label className="info-property">
            {translate.pages.userProfile.label_2}
          </label>
          <label className="info-value">{user.phoneNumber}</label>
        </span>
        <span className="user-info-item">
          <label className="info-property">
            {translate.pages.userProfile.label_3}
          </label>
          <label className="info-value">{user.fullAddress}</label>
        </span>
        <span className="user-info-item">
          <label className="info-property">
            {translate.pages.userProfile.label_4}
          </label>
          <label className="info-value">{user.postalCode}</label>
        </span>
        <span className="user-info-item">
          <label className="info-property">
            {translate.pages.userProfile.label_5}
          </label>
          <label className="info-value">{user.city}</label>
        </span>
        <span className="user-info-item">
          <label className="info-property">
            {translate.pages.userProfile.label_6}
          </label>
          <label className="info-value">{user.province}</label>
        </span>
        <span className="user-info-item">
          <label className="info-property">
            {translate.pages.userProfile.label_7}
          </label>
          <label className="info-value">
            {!!user.country
              ? capitalize(
                  countriesList[user.country].translations[computeLang()] ||
                    countriesList[user.country].countryEnglishName
                )
              : user.country}
          </label>
        </span>
        <span className="user-info-item">
          <label className="info-property">
            {translate.pages.userProfile.label_8}
          </label>
          <label className="info-value">
            {translate.pages.userProfile[user.gender.toLowerCase()]}
          </label>
        </span>
        <span className="user-info-item">
          <label className="info-property">
            {translate.pages.userProfile.label_9}
          </label>
          <label className="info-value">{formatDate(user.birthday)}</label>
        </span>
        <span className="user-info-item">
          <label className="info-property">
            {translate.pages.userProfile.label_10}
          </label>
          <label className="info-value">{user.occupation}</label>
        </span>
        <span className="user-info-item">
          <label className="info-property">
            {translate.pages.userProfile.label_11}
          </label>
          <label className="info-value">
            {user.roles
              .map((role) => {
                const arr = role.split("");
                arr[0] = arr[0].toUpperCase();
                return arr.join("");
              })
              .join(", ")}
          </label>
        </span>
      </div>
      <div id="more-options" style={{ width: "100%", marginTop: "15px" }}>
        <h4 style={{ margin: 0, padding: 0 }}>
          {translate.pages.userProfile.h4_1}
        </h4>
        <hr style={{ margin: "5px 0 10px 0" }} />
        <Link to="/transactions/init">
          {translate.pages.userProfile.link_1}
        </Link>
        <br />
        <Link to="/transactions/track">
          {translate.pages.userProfile.link_2}
        </Link>
        <br />
        <Link to="/user/transactions">
          {translate.pages.userProfile.link_3}
        </Link>
        <br />
        <Link to="/user/change-password">
          {translate.pages.userProfile.link_4}
        </Link>
        <br />
        <Link to="/user/change-email">
          {translate.pages.userProfile.link_5}
        </Link>
      </div>
    </div>
  );
};

export default UserProfilePage;
