import { useNavigate } from "react-router-dom";
import { currentUserStore } from "../../store/current-user.store";
import { useTranslate } from "../../i18n/translation.i18n";

const HomePage = () => {
  const { translate } = useTranslate();
  const navigate = useNavigate();

  return (
    <div
      id="track-transaction-page"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "15px 0",
      }}
    >
      <strong
        align="center"
        style={{
          display: "inline-block",
          width: "100%",
          margin: "10px 0 20px 0",
        }}
      >
        {translate.pages.home.strong_1}
      </strong>
      <p style={{ marginBottom: 0, textAlign: "center", padding: "0 10px" }}>
        {!!currentUserStore.snapshot.userId
          ? translate.pages.home.p_1
          : translate.pages.home.p_2}
      </p>
      <div>
        <button
          onClick={() =>
            navigate(
              !!currentUserStore.snapshot.userId
                ? "/transactions/init"
                : "/auth/sign-up"
            )
          }
          style={{ width: "200px" }}
          className="button"
        >
          {!!currentUserStore.snapshot.userId
            ? translate.pages.home.button_1_1
            : translate.pages.home.button_1_2}
        </button>
      </div>
      <hr style={{ width: "100%" }} />
      <p style={{ marginBottom: 0, textAlign: "center", padding: "0 10px" }}>
        {translate.pages.home.p_3}
      </p>
      <div>
        <button
          onClick={() => navigate("/transactions/track")}
          style={{ width: "200px" }}
          className="button"
        >
          {translate.pages.home.button_2}
        </button>
      </div>
    </div>
  );
};

export default HomePage;
