/**
 * @typedef {{
 *   path: string;
 *   page: React.FunctionComponent | React.Component;
 *   children?: RouteList;
 *   data?: any;
 *   searchParams?: Record<string, string>;
 *   canActivate?: { () => boolean } | boolean;
 *   redirectOnFail?: string;
 *   redirectUrl?: string;
 *   name: string;
 * }} Route
 * @typedef { Array<Route> } RouteList
 */

import HomePage from "../pages/home/home.page";
import NotFoundPage from "../pages/not-found.page";

/** @type { RouteList } */
const rootRoutes = [
  {
    path: "/",
    page: HomePage,
  },
  {
    path: "*",
    page: NotFoundPage
  },
];

export default rootRoutes;
